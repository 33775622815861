// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainRoutes from "./MainRoutes";

const AppRouter = () => {
  const router = createBrowserRouter(
    [
      MainRoutes(),
      // LoginRoutes, // Add other routes here if needed
    ],
    { basename: "/" }
  );

  return <RouterProvider router={router} />;
};

export default AppRouter;
