// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

const PREFIXES = `PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
PREFIX sorsh: <http://example.org/rosenzweig/annotation_shapes/>
PREFIX sor: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotation#>
PREFIX sord: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationData#>
PREFIX sorvoc: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationVocabulary#>
PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>
PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
PREFIX roger: <https://www.sub.uni-goettingen.de/roger/schema#>
PREFIX dash: <http://datashapes.org/dash#>
PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
PREFIX dcterms: <http://purl.org/dc/terms#>
PREFIX bf: <http://id.loc.gov/ontologies/bibframe-2-2-0#>
PREFIX oa: <http://www.w3.org/ns/oa#>
PREFIX foaf: <http://xmlns.com/foaf/0.1#>
PREFIX owl: <http://www.w3.org/2002/07/owl#>
PREFIX intro: <https://w3id.org/lso/intro/beta202304#>
PREFIX dct: <https://purl.org/dc/terms/>
PREFIX locvoc: <http://id.loc.gov/vocabulary/relators/#>`;

//seeAlsoShape:

export const seeAlsoShape = `"""
${PREFIXES}
select ?sourcepassage ?exact where
{ ?sourcepassage a oa:Annotation ;
  oa:hasTarget ?target .
  ?target oa:hasSelector ?selector .
  ?selector oa:refinedBy ?refinedBy .
  ?refinedBy oa:exact ?exact . }"""@en`;

//sourceEquivalentPassageShape / literarySourceStatedShape / LiterarySourceProposedShape:

export const sourceEquivalentPassageShape = `"""
${PREFIXES}
select ?sourcepassage ?wording where
{ ?sourcepassage a sor:SourcePassage ;
  intro:R44_has_wording ?wording . }"""@en`;

//researchEquivalentPassageShape / notedInShape:

export const researchEquivalentPassageShape = `"""
${PREFIXES}
select ?sourcepassage ?mainTitle where
{ ?sourcepassage a sor:ResearchLiteraturePassage ;
  bf:partOf ?instance .
  ?instance bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }"""@en`;

//egotextEquivalentPassageShape / sorsh: egotextShape:

export const egotextEquivalentPassageShape = `"""
${PREFIXES}
select ?egotextpassage ?wording where
{ ?egotextpassage a sor:EgotextPassage ;
  intro:R44_has_wording ?wording . }"""@en`;

//sorsh: passagePartOfShape / sorsh: instancePartOfShape:

export const passagePartOfShape = `"""
${PREFIXES}
select ?instance ?mainTitle where
{ ?instance a bf:Instance ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }"""@en`;

//sorsh: instanceOfShape:

export const instanceOfShape = `"""
${PREFIXES}
select ?work ?mainTitle where
{ ?work a bf:Work ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }"""@en`;

//hasTypeShape / editorInstanceRoleShape / translatorInstanceRoleShape / authorWorkRoleShape / recipientWorkRoleShape:

export const hasTypeShape = `"""
${PREFIXES}
select ?concept ?prefLabel where
{ ?concept rdf:type skos:Concept ;
  skos:prefLabel ?prefLabel . }"""@en`;

//authorContributionAgentShape / recipientContributionAgentShape / whichAuthorStatedShape / authorProposedShape / editorContributionAgentShape / translatorContributionAgentShape:

export const authorContributionAgentShape = `"""
${PREFIXES}
select ?person ?name where
{ ?person a foaf:Person ;
  foaf:name ?name . }"""@en`;

//publisherAgentShape:

export const publisherAgentShape = `"""
${PREFIXES}
select ?publisher ?name where
{ ?publisher a sor:Publisher ;
  foaf:name ?name . }"""@en`;

//identifiedByShape:

export const identifiedByShape = `"""
${PREFIXES}
select ?isbn ?name where
{ ?isbn a bf:Isbn ;
  foaf:name ?name . }"""@en`;

//grantingInstitutionShape:

export const grantingInstitutionShape = `"""
${PREFIXES}
select ?institution ?name where
{ ?institution a sor:GrantingInstitution ;
  foaf:name ?name . }"""@en`;

//placeShape:

export const placeShape = `"""
${PREFIXES}
select ?place ?name where
{ ?place a bf:Place ;
  foaf:name ?name . }"""@en`;

export const hasInstanceContributorShape = `"""
${PREFIXES}
select ?person ?name where
{ ?person a foaf:Person ;
  foaf:name ?name . }"""@en`;

//inSchemeShape:

export const inSchemeShape = `"""
${PREFIXES}
select ?scheme ?title where
{ ?scheme a skos:ConceptScheme ;
  dct:title ?title . }"""@en`;

//broaderShape:

export const broaderShape = `"""
${PREFIXES}
select ?concept ?label where
{ ?concept a skos:Concept ;
  skos:prefLabel ?label . }"""@en`;

export const hasWorkContributorShape = `"""
${PREFIXES}
select ?concept ?prefLabel where
{ ?concept skos:broader sorvoc:WorkContributor ;
  skos:prefLabel ?prefLabel . }"""@en`;
