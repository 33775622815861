// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: CC0-1.0

import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import ThemeCustomization from "./themes/index.jsx";
import AppRouter from "./routes";
import "./assets/css/custom-app.css";
import { AppProvider } from "./context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeCustomization>
        <AppRouter />
      </ThemeCustomization>
    </AppProvider>
  </React.StrictMode>
);
