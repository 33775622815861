// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import DashboardLayout from "../layout/Dashboard";
import TeiText from "@fe/roger-frames";
import MainDashboard from "../pages/Dashboard/index";
import { useAppContext } from "../context/AppContext";
import NodeComponent from "../pages";
import { NodeShapes } from "../data/NodeShapes";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = () => {
  const { config } = useAppContext();
  return {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <MainDashboard />,
      },
      {
        path: "/dashboard",
        element: <MainDashboard />,
      },
      {
        path: "annotate",
        element: <TeiText config={config} />,
      },
      ...NodeShapes.map((node) => ({
        path: node.path,
        element: <NodeComponent node={node} />,
      })),
    ],
  };
};

export default MainRoutes;
