// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2

import React from "react";
import Stack from "@mui/material/Stack";
import { Roger } from "@fe/roger-core";
import type { Quad } from "@rdfjs/types";
import type { SparqlConfig } from "../sparql/createAnnotation";
import { createAnnotation } from "../sparql/createAnnotation";
import { useSnackbar } from "../hooks/useSnackbar";
interface endpoints {
  endpoint: string;
  update: string;
}
interface RogerFrameProps {
  schema: string;
  config: SparqlConfig;
  initialQuads: string;
  endpoints: endpoints;
}

export default function RogerFrame({
  schema,
  config,
  initialQuads,
  endpoints,
}: RogerFrameProps) {
  const [changed, setChanged] = React.useState<boolean>(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  async function handleSave(quads: Quad[]) {
    try {
      const response = await createAnnotation(quads, config);
      if (response?.success) {
        showSnackbar(response.message, "success");
      } else {
        showSnackbar("Failed to add annotation!", "error");
      }
    } catch (error) {
      console.error("Error saving annotation:", error);
      showSnackbar("An unexpected error occurred!", "error");
    }
  }

  return (
    <Stack direction="row" spacing={2}>
      <Roger
        schema={schema}
        data={initialQuads}
        onSave={config ? handleSave : undefined}
        useChanged={[changed, setChanged]}
        endpoints={endpoints}
      />
      {SnackbarComponent}
    </Stack>
  );
}
