// SPDX-FileCopyrightText: 2022 Georg-August-Universität Göttingen
// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
// SPDX-License-Identifier: EUPL-1.2
import React, { createContext, useContext } from "react";
import Schema from "../data/Schema";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const endpoints = {
    endpoint: `${process.env.REACT_APP_API_SPARQL}`,
    update: `${process.env.REACT_APP_API_STATEMENTS}`,
  };

  const config = {
    sparqlEndpoint: `${process.env.REACT_APP_API_SPARQL}`,
    sparqlUpdateEndpoint: `${process.env.REACT_APP_API_STATEMENTS}`,
  };
  const schema = Schema;

  return (
    <AppContext.Provider value={{ endpoints, config, schema }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
