// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import { v4 as uuidv4 } from "uuid";

interface DataInfo {
  dataInfo: string;
  targetId: string;
  startOffset: number;
  endOffset: number;
  selectedText: string;
}

interface CombinedData {
  startData: DataInfo;
  endData: DataInfo;
}

const rdfData = (combinedData: CombinedData) => {
  const created = new Date().toISOString();
  const uniqueId = uuidv4();

  return `
          @prefix sh: <http://www.w3.org/ns/shacl#> .
          @prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
          @prefix sorsh: <http://example.org/rosenzweig/annotation_shapes/> .
          @prefix sor: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotation#> .
          @prefix sord: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationData#> .
          @prefix sorvoc: <http://www.example.org/met/ontologies/2023/0/RosenzweigAnnotationVocabulary#> .
          @prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
          @prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
          @prefix roger: <https://www.sub.uni-goettingen.de/roger/schema#> .
          @prefix dash: <http://datashapes.org/dash#> .
          @prefix skos: <http://www.w3.org/2004/02/skos/core#> .
          @prefix dcterms: <http://purl.org/dc/terms#> .
          @prefix bf: <http://id.loc.gov/ontologies/bibframe-2-2-0#> .
          @prefix oa: <http://www.w3.org/ns/oa#> .
          @prefix foaf: <http://xmlns.com/foaf/0.1#> .
          @prefix owl: <http://www.w3.org/2002/07/owl#> .
          @prefix intro: <https://w3id.org/lso/intro/beta202304#> .

          ### Specific to controlled vocabularies
          @prefix dct: <https://purl.org/dc/terms/> .
          @prefix locvoc: <http://id.loc.gov/vocabulary/relators/#> .

          ### Web annotation model

          sord:annotation_${uniqueId} a oa:Annotation ;

          ### User management
          dcterms:created "${created}"^^xsd:dateTime ;
          dcterms:modified "${created}"^^xsd:dateTime ;

          # Target Shape
      oa:hasTarget
          [a oa:SpecificResource;
              oa:hasSelector
                  [a oa:RangeSelector;
                      oa:hasStartSelector
                          [a oa:XPathSelector;
                              rdf:value "${combinedData.startData.dataInfo}" ];
                      oa:hasEndSelector
                          [a oa:XPathSelector;
                              rdf:value "${combinedData.endData.dataInfo}"];
                      oa:refinedBy 
                          [a oa:TextQuoteSelector;
                              oa:exact "${combinedData.endData.selectedText}"], 
                          [a sor:XMLIDSelector;
                              sor:startID "${combinedData.startData.targetId}";
                              sor:endID "${combinedData.endData.targetId}"],
                          [a oa:DataPositionSelector;
                              oa:start ${combinedData.startData.startOffset};
                              oa:end ${combinedData.endData.endOffset}]
                  ];

              oa:hasSource <https://gitlab.gwdg.de/-/ide/project/star/rosenzweig-models/edit/main/-/RDF/Rosenzweig_exampledata.ttl>;
              ];

          ### Controlled Vocabularies
          sorvoc:Author a skos:Concept .
          sorvoc:Editor a skos:Concept .
          sorvoc:Translator a skos:Concept .
          sorvoc:Recipient a skos:Concept .
          sorvoc:DirectReference a skos:Concept .
          sorvoc:Paraphrase a skos:Concept .
          sorvoc:TechnicalTerm a skos:Concept .
        `;
};

export default rdfData;
