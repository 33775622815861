// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2

import Prefixes from "./Prefixes";

const AnnotationsCountQuery = `
          SELECT (COUNT(DISTINCT ?annotation) AS ?annotationCount)
          WHERE {
            ?annotation a oa:Annotation
          }
        `;

const PropertiesCountQuery = `
          SELECT (COUNT(DISTINCT ?predicate) AS ?predicateCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;

const ObjectsCountQuery = `
          SELECT (COUNT(DISTINCT ?object) AS ?objectCount)
          WHERE {
            ?annotation a oa:Annotation ;
                        ?predicate ?object .
          }
        `;

const AllAnnotations = `
        SELECT ?annotation ?target ?exact ?created
                WHERE {
                ?annotation a oa:Annotation ;
                        dcterms:created ?created .

                OPTIONAL {
                ?annotation oa:hasTarget ?target .
                ?target a oa:SpecificResource ;
                        oa:hasSelector ?rangeSelector .

                ?rangeSelector a oa:RangeSelector ;
                                oa:refinedBy ?textQuoteSelector .

                ?textQuoteSelector a oa:TextQuoteSelector ;
                                oa:exact ?exact .
                }
        }`;

const GetSingleAnnotation = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //       <${decodedUri}> ?Px ?Ox .
  //       }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllPersons = `select ?person ?name
                {
                ?person a foaf:Person ;
                        foaf:name ?name
                }`;

const GetSinglePerson = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //     <${decodedUri}> ?Px ?Ox .
  //   }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllSourcePassages = `
select ?sourcepassage ?wording where
{ ?sourcepassage a sor:SourcePassage ;
  intro:R44_has_wording ?wording . }`;

const GetSingleSourcePassage = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllResearchLiteraturePassages = `
select ?sourcepassage ?mainTitle where
{ ?sourcepassage a sor:ResearchLiteraturePassage ;
  bf:partOf ?instance .
  ?instance bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }`;

const GetSingleResearchLiteraturePassage = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllInstances = `select ?instance ?mainTitle where
{ ?instance a bf:Instance ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }`;

const GetSingleInstance = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //     <${decodedUri}> ?Px ?Ox .
  //   }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllEgotextPassages = `select ?egotextpassage ?wording where
{ ?egotextpassage a sor:EgotextPassage ;
  intro:R44_has_wording ?wording . }`;

const GetSingleEgotextPassage = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllWorks = `select ?work ?mainTitle where
{ ?work a bf:Work ;
  bf:title ?title .
  ?title bf:mainTitle ?mainTitle . }
  `;

const GetSingleWork = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //     <${decodedUri}> ?Px ?Ox .
  //   }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllPlaces = `select ?place ?name where
{ ?place a bf:Place ;
  foaf:name ?name . }
  `;

const GetSinglePlace = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllPublishers = `select ?publisher ?name where
{ ?publisher a sor:Publisher ;
  foaf:name ?name . }
  `;

const GetSinglePublisher = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllInstitutions = `select ?institution ?name where
{ ?institution a sor:GrantingInstitution ;
  foaf:name ?name . }
  `;

const GetSingleInstitution = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const AllIsbns = `select ?isbn ?name where
{ ?isbn a bf:Isbn ;
  foaf:name ?name . }
  `;

const GetSingleIsbn = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  return ` CONSTRUCT WHERE {
      <${decodedUri}> ?Px ?Ox .
    }`;
};

const DeleteQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
        DELETE WHERE {
            <${decodedUri}> ?p ?o .
        }
    `;
};

const DeleteAnnotationQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
    DELETE { 
        <${decodedUri}> a oa:Annotation;
                       dcterms:created ?created;
                       dcterms:modified ?modified;
                       oa:hasTarget ?specificResource;
                       oa:hasBody ?body .

        ?specificResource a oa:SpecificResource;
                          oa:hasSelector ?rangeSelector;
                          oa:hasSource ?source .

        ?rangeSelector a oa:RangeSelector;
                       oa:hasStartSelector ?startSelector;
                       oa:hasEndSelector ?endSelector;
                       oa:refinedBy ?quoteSelector, ?xmlIdSelector, ?dataPositionSelector .

        ?startSelector a oa:XPathSelector;
                       rdf:value ?value .

        ?endSelector a oa:XPathSelector;
                     rdf:value ?value .

        ?quoteSelector a oa:TextQuoteSelector;
                       oa:exact ?exact .

        ?xmlIdSelector a sor:XMLIDSelector;
                       sor:startID ?startId;
                       sor:endID ?endId .

        ?dataPositionSelector a oa:DataPositionSelector;
                              oa:start ?start;
                              oa:end ?end .

        ?body a intro:INT7_Interpretament;
              sor:note ?note;
              intro:R21_identifies ?intertextualRelationship;
              sor:literarySource ?literarySource;
              sor:author ?author.
              #sor:notedIn ?researchLiteraturePassage;
              #sor:egotext ?body0 .

        ?intertextualRelationship a intro:INT3_IntertextualRelationship;
                                  sor:hasFormalAppearance ?formalAppearance;
                                  intro:R19_has_type ?concept .

        ?formalAppearance a sor:FormalAppearance;
                          sor:hasQuotationMarks ?quotation;
                          sor:titleStated ?titleStated;
                          sor:authorStated ?authorStated;
                          sor:author ?explicitlyStatedAuthor;
                          sor:literarySource ?explicitlyStatedLiterarySource .

        #?explicitlyStatedAuthor a foaf:Person .
        ?concept a skos:Concept .
        #?explicitlyStatedLiterarySource a sor:SourcePassage .
        #?literarySource a sor:SourcePassage .
        #?author a foaf:Person .
        #?researchLiteraturePassage a sor:ResearchLiteraturePassage .
        #?body0 a sor:EgotextPassage .

        #?a1 a foaf:Person . 
        #?sp1 a sor:SourcePassage .

        # Additional nested deletions to remove the remaining triples
        ?body intro:R21_identifies ?intertextualRelationship .
        ?intertextualRelationship sor:hasFormalAppearance ?formalAppearance .
    } 
    WHERE {
        <${decodedUri}> a oa:Annotation;
                       dcterms:created ?created;
                       dcterms:modified ?modified;
                       oa:hasTarget ?specificResource;
                       oa:hasBody ?body .

        ?specificResource a oa:SpecificResource;
                          oa:hasSelector ?rangeSelector;
                          oa:hasSource ?source .

        ?rangeSelector a oa:RangeSelector;
                       oa:hasStartSelector ?startSelector;
                       oa:hasEndSelector ?endSelector;
                       oa:refinedBy ?quoteSelector, ?xmlIdSelector, ?dataPositionSelector .

        ?startSelector a oa:XPathSelector;
                       rdf:value ?value .

        ?endSelector a oa:XPathSelector;
                     rdf:value ?value .

        OPTIONAL {
            ?quoteSelector a oa:TextQuoteSelector;
            oa:exact ?exact .
        }

        OPTIONAL {
            ?xmlIdSelector a sor:XMLIDSelector;
            sor:startID ?startId;
            sor:endID ?endId .
        }

        OPTIONAL {
            ?dataPositionSelector a oa:DataPositionSelector;
            oa:start ?start;
            oa:end ?end .
        }

        OPTIONAL {
            ?body a intro:INT7_Interpretament;
                  sor:note ?note;
                  intro:R21_identifies ?intertextualRelationship;
                  sor:literarySource ?literarySource;
                  sor:author ?author;
                  sor:notedIn ?researchLiteraturePassage;
                  sor:egotext ?body0 .
        }

        OPTIONAL {
            ?intertextualRelationship a intro:INT3_IntertextualRelationship;
                                      sor:hasFormalAppearance ?formalAppearance;
                                      intro:R19_has_type ?concept .
        }

        OPTIONAL {
            ?formalAppearance a sor:FormalAppearance;
                              sor:hasQuotationMarks ?quotation;
                              sor:titleStated ?titleStated;
                              sor:authorStated ?authorStated;
                              sor:author ?explicitlyStatedAuthor;
                              sor:literarySource ?explicitlyStatedLiterarySource .
        }

        OPTIONAL { ?explicitlyStatedAuthor a foaf:Person . }
        OPTIONAL { ?concept a skos:Concept . }
        OPTIONAL { ?explicitlyStatedLiterarySource a sor:SourcePassage . }
        OPTIONAL { ?literarySource a sor:SourcePassage . }
        OPTIONAL { ?author a foaf:Person . }
        OPTIONAL { ?researchLiteraturePassage a sor:ResearchLiteraturePassage . }
        OPTIONAL { ?body0 a sor:EgotextPassage . }

        # Additional nested matches to ensure all triples are captured
        OPTIONAL { ?body intro:R21_identifies ?intertextualRelationship . }
        OPTIONAL { ?intertextualRelationship sor:hasFormalAppearance ?formalAppearance . }
        OPTIONAL { ?body sor:note ?note . }
        OPTIONAL { ?a1 a foaf:Person . }
        OPTIONAL { ?sp1 a sor:SourcePassage . }
    }
    `;
};

const DeleteEditionQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
   DELETE {
        <${decodedUri}> a bf:Instance;
                bf:partOf ?partOf;
                bf:electronicLocator ?electronicLocator;
                bf:pages ?pages;
                bf:identifiedBy ?identifiedBy;
                bf:dissertation ?dissertation;
                bf:title ?title;
                bf:contribution ?contribution;
                bf:provisionActivity ?provisionActivity;
                bf:instanceOf ?instanceOf.

        ?provisionActivity a bf:Publication;
                bf:agent ?publAgent;
                bf:place ?place;
                bf:date ?date.

        ?contribution a bf:Contribution;
                bf:role ?role;
                bf:agent ?contrAgent.

        ?dissertation a bf:Dissertation;
                bf:degree ?degree;
                bf:grantingInstitution ?grantingInstitution.

        ?title a bf:Title;
                bf:mainTitle ?mainTitle;
                bf:subtitle ?subtitle.
    }
    WHERE {
        <${decodedUri}> a bf:Instance.

        OPTIONAL {
            <${decodedUri}> bf:partOf ?partOf;
                bf:electronicLocator ?electronicLocator;
                bf:pages ?pages;
                bf:identifiedBy ?identifiedBy;
                bf:dissertation ?dissertation;
                bf:title ?title;
                bf:contribution ?contribution;
                bf:provisionActivity ?provisionActivity;
                bf:instanceOf ?instanceOf.
        }

        OPTIONAL {
            ?provisionActivity a bf:Publication;
                       bf:agent ?publAgent;
                       bf:place ?place;
                       bf:date ?date.
        }

        OPTIONAL {
            ?contribution a bf:Contribution;
                       bf:role ?role;
                       bf:agent ?contrAgent.
        }

        OPTIONAL {
            ?dissertation a bf:Dissertation;
                     bf:degree ?degree;
                     bf:grantingInstitution ?grantingInstitution.
        }

        OPTIONAL {
            ?title a bf:Title;
                       bf:mainTitle ?mainTitle;
                       bf:subtitle ?subtitle.
        }
    }
    `;
};

const DeleteWorkQuery = (uri: string) => {
  const decodedUri = decodeURIComponent(uri);
  return `
  ${Prefixes}
DELETE { 
    <${decodedUri}> a bf:Work;
                   sor:WikidataMatch ?WikidataMatch;
                   sor:GNDMatch ?GNDMatch;
                   sor:LOCMatch ?LOCMatch;
                   bf:originDate ?originDate;
                   bf:title ?workTitle;
                   bf:contribution ?workContribution.

    ?workContribution a bf:Contribution;
                   bf:role ?role;
                   bf:agent ?contrAgent.

    ?workTitle a bf:Title;
                   bf:mainTitle ?mainTitle;
                   bf:subtitle ?subtitle.
} 
WHERE {
    <${decodedUri}> a bf:Work.

    OPTIONAL {
        <${decodedUri}> 
            sor:WikidataMatch ?WikidataMatch;
            sor:GNDMatch ?GNDMatch;
            sor:LOCMatch ?LOCMatch.
    }

    OPTIONAL {
        <${decodedUri}> bf:originDate ?originDate;
                       bf:title ?workTitle;
                       bf:contribution ?workContribution.
    }

    OPTIONAL {
        ?workContribution a bf:Contribution;
            bf:role ?role;
            bf:agent ?contrAgent.
    }

    OPTIONAL {
        ?workTitle a bf:Title;
            bf:mainTitle ?mainTitle;
            bf:subtitle ?subtitle.
    }
}

    `;
};

const GetSingleNode = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //       <${decodedUri}> ?Px ?Ox .
  //       }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllConcepts = `select ?concept ?label where
{ ?concept a skos:Concept ;
  skos:prefLabel ?label . }`;

const GetSingleConcept = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //     <${decodedUri}> ?Px ?Ox .
  //   }`;
  return `DESCRIBE <${decodedUri}>`;
};

const AllConceptSchemes = `select ?scheme ?title where
{ ?scheme a skos:ConceptScheme ;
  dct:title ?title . }`;

const GetSingleConceptScheme = (uri) => {
  const decodedUri = decodeURIComponent(uri);
  // return ` CONSTRUCT WHERE {
  //     <${decodedUri}> ?Px ?Ox .
  //   }`;
  return `DESCRIBE <${decodedUri}>`;
};

export {
  GetSingleNode,
  AnnotationsCountQuery,
  PropertiesCountQuery,
  ObjectsCountQuery,
  AllAnnotations,
  GetSingleAnnotation,
  GetSinglePerson,
  AllPersons,
  AllSourcePassages,
  GetSingleSourcePassage,
  AllResearchLiteraturePassages,
  GetSingleResearchLiteraturePassage,
  AllInstances,
  GetSingleInstance,
  AllEgotextPassages,
  GetSingleEgotextPassage,
  AllWorks,
  GetSingleWork,
  AllPlaces,
  GetSinglePlace,
  AllPublishers,
  GetSinglePublisher,
  AllInstitutions,
  GetSingleInstitution,
  AllIsbns,
  GetSingleIsbn,
  AllConcepts,
  GetSingleConcept,
  AllConceptSchemes,
  GetSingleConceptScheme,
  DeleteQuery,
  DeleteAnnotationQuery,
  DeleteEditionQuery,
  DeleteWorkQuery,
};
