// SPDX-FileCopyrightText: 2024 Universität Göttingen
//
// SPDX-License-Identifier: EUPL-1.2
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { AllIsbns, GetSingleIsbn } from "../../data/Queries";
import { AxiosResponse } from "axios";
import RogerFrame from "../../utils/RogerFrame";
import { useSnackbar } from "../../hooks/useSnackbar";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import { DeleteResource, FetchData } from "../../utils/helpers";
import { FetchData as ttl } from "../../api/FetchData";
import { useAppContext } from "../../context/AppContext";

interface Binding {
  name?: {
    value: string;
  };
  isbn?: {
    value: string;
  };
}

interface ParsedBinding {
  id: number;
  name: string | null;
  isbn: string | null;
}

const parser = (bindings: Binding[]): ParsedBinding[] => {
  return bindings.map((binding, index) => ({
    id: index,
    name: binding.name?.value ?? null,
    isbn: binding.isbn?.value ?? null,
  }));
};

const Isbns: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const { endpoints, config, schema } = useAppContext();
  const [isbns, setisbns] = useState([]);
  const [initialQuads, setInitialQuads] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbar();
  const { showConfirmDialog, ConfirmDialogComponent } = useConfirmDialog();

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const fetchData = async () => {
    const bindings = await FetchData(AllIsbns);
    const isbns = parser(bindings);
    setisbns(isbns);
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const handleEdit = (uri: string) => {
    const Request = {
      query: GetSingleIsbn(uri),
      headers: {
        "Content-Type": "application/sparql-query",
      },
      url: `${process.env.REACT_APP_API_SPARQL}`,
    };
    const fetch = async () => {
      const result = await ttl(Request);
      if ((result as AxiosResponse).data) {
        const axiosResponse = result as AxiosResponse;
        setInitialQuads(axiosResponse.data);
        handleDialogOpen();
      }
    };
    fetch();
  };

  const handleDelete = (uri: string) => {
    showConfirmDialog("Are you sure you want to delete this isbn?", () =>
      confirmDelete(uri)
    );
  };

  const confirmDelete = async (uri: string) => {
    const success = await DeleteResource(uri);
    if (success) {
      showSnackbar("isbn deleted successfully!", "success");
      fetchData();
    } else {
      showSnackbar("Failed to delete isbn", "error");
    }
  };

  const onSave = async () => {
    fetchData();
    handleDialogClose();
    showSnackbar("Resource updated successfully", "success");
  };

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          width="100%"
        >
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.isbn)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleDelete(params.row.isbn)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          height: 400,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isbns.length > 0 ? (
          <DataGrid
            rows={isbns}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        ) : (
          <Typography variant="h6" color="textSecondary">
            No ISBNs, Add a new one using "Add" button
          </Typography>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="edit-dialog-title"
      >
        <DialogContent>
          <RogerFrame
            schema={schema}
            initialQuads={initialQuads}
            endpoints={endpoints}
            config={config}
            onSave={onSave}
            mode="edit"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      {SnackbarComponent}
      {ConfirmDialogComponent}
    </>
  );
};

export default Isbns;
