// SPDX-FileCopyrightText: 2023 Georg-August-Universität Göttingen
//

import path from "path";

// SPDX-License-Identifier: EUPL-1.2
export const NodeShapes = [
  {
    label: "Annotations",
    path: "searchannotation",
    namespace: "bf:annotation",
    name: "bf:Annotation",
  },
  {
    label: "Persons",
    path: "searchperson",
    namespace: "sord:person",
    name: "foaf:Person",
  },
  {
    label: "Source Passages",
    path: "searcsource",
    namespace: "sord:passage",
    name: "sor:SourcePassage",
  },
  {
    label: "Egotext Passages",
    path: "searchegotext",
    namespace: "sor:egotext",
    name: "sor:EgotextPassage",
  },
  {
    label: "Research Literature Passages",
    path: "searchresearch",
    namespace: "sord:passage",
    name: "sor:ResearchLiteraturePassage",
  },
  {
    label: "Editions",
    path: "searchinstance",
    namespace: "sord:instance",
    name: "bf:Instance",
  },
  {
    label: "Works",
    path: "searchwork",
    namespace: "bf:instanceOf",
    name: "bf:Work",
  },
  {
    label: "Institutions",
    path: "searchinstitution",
    namespace: "bf:grantingInstitution",
    name: "sor:GrantingInstitution",
  },
  {
    label: "ISBNs",
    path: "searchisbn",
    namespace: "bf:identifiedBy",
    name: "bf:Isbn",
  },
  {
    label: "Places",
    path: "searchplace",
    namespace: "bf:place",
    name: "bf:Place",
  },
  {
    label: "Publishers",
    path: "searchpublisher",
    namespace: "bf:agenr",
    name: "sor:Publisher",
  },
  {
    label: "Concepts",
    path: "searchconcept",
    namespace: "sorsh:ConceptShape",
    name: "skos:Concept",
  },
  {
    label: "Concept Schemes",
    path: "searchconceptscheme",
    namespace: "sorsh:SchemeShape",
    name: "skos:ConceptScheme",
  },
];
